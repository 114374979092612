import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserService } from '../../shared/services/service-proxies/service-proxies';
import {
  actionUserRegister,
  actionUserRegisterSuccess,
  actionUserRegisterFail
} from './register.actions';

@Injectable()
export class RegisterEffects {
  register = createEffect(() =>
    this.actions$.pipe(
      ofType(actionUserRegister),
      mergeMap((action) =>
        this._userService.create(action.user).pipe(
          map((response) => actionUserRegisterSuccess({ response })),
          catchError((error: any) =>
            of(actionUserRegisterFail({ payload: error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _userService: UserService,
    private store: Store
  ) {}
}
