import { Action, createReducer, on } from '@ngrx/store';
import { TitleModel } from '../../shared/models/titles-model';
import * as actions from './title.action';

export const initialState: TitleModel = {
  titulo: '',
  subtitulo: '',
  contenido: '',
  hadButton: false,
  url: ''
};

const reducer = createReducer(
  initialState,
  on(actions.actionSetTitleModel, (state, { titleModel }) => ({
    ...state,
    ...titleModel
  }))
);

export function titleReducer(state: TitleModel | undefined, action: Action) {
  return reducer(state, action);
}
