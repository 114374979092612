import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from '../notifications/notification.service';
import { ErrorService } from './error.service';
import { Store } from '@ngrx/store';
import { authRefreshTokenFailure } from '../auth/auth.actions';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private injector: Injector,
    private notificationsService: NotificationService
  ) {
    super();
  }
  handleError(error: Error | HttpErrorResponse | any) {
    const errorService = this.injector.get(ErrorService);
    const storeService = this.injector.get(Store);
    let message;
    if (error.url) {
      const action = error.url.split('/'); // dividir el endpoint: https:/genus.com/api/user
      const point = action[action.length - 1]; // fin del endpoint: user
      if (error.url.indexOf('/token/refresh') !== -1 && error.status === 500) {
        storeService.dispatch(authRefreshTokenFailure());
      } else if (error instanceof HttpErrorResponse) {
        if (error.error) {
          this.getErrorBlob(error.error).then((errorPayload) => {
            error['errorPayload'] = errorPayload;
            message = errorService.getServerErrorMessage(error, point);
            this.notificationsService.warn(message);
          });
        } else {
          message = errorService.getServerErrorMessage(error, point);
          this.notificationsService.warn(message);
        }
      } else {
        message = errorService.getClientErrorMessage(error);
        this.notificationsService.warn(message);
      }
    } else {
      message = errorService.getClientErrorMessage(error);
      console.warn(error, message);
    }
    super.handleError(error);
  }
  getError(error): {} {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(JSON.parse(reader.result as string));
      };
      reader.onerror = () => {
        reject(new Error('Unable to read..'));
      };
      reader.readAsText(error);
    });
  }
  async getErrorBlob(blob: Blob) {
    try {
      const contentBuffer = await this.getError(blob);
      return contentBuffer;
    } catch (err) {
      console.log(err);
    }
  }
}
