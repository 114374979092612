import { Action, createReducer, on } from '@ngrx/store';
import { ResponseResultAJAXOfIdentityResult } from '../../shared/services/service-proxies/service-proxies';
import {
  actionUserRegister,
  actionUserRegisterFail,
  actionUserRegisterSuccess
} from './register.actions';
import { RegisterState } from './register.model';

export const initialState: RegisterState = {
  response: {} as ResponseResultAJAXOfIdentityResult,
  isLoading: false,
  errorMessage: null,
  isRegisterFailure: false,
  isRegisterSuccess: false
};

const reducer = createReducer(
  initialState,
  on(actionUserRegister, (state) => ({
    ...state,
    isLoading: true,
    isRegisterFailure: false,
    isRegisterSuccess: false
  })),
  on(actionUserRegisterSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    response: response,
    isRegisterFailure: false,
    isRegisterSuccess: true,
    errorMessage: null
  })),
  on(actionUserRegisterFail, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isRegisterFailure: true,
    errorMessage: payload,
    isRegisterSuccess: false
  }))
);

export function registerReducer(
  state: RegisterState | undefined,
  action: Action
) {
  return reducer(state, action);
}
