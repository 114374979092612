import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { actionResetProfile } from '../../features/dashboard/user/user.actions';
import {
  AuthService,
  TokenService
} from '../../shared/services/service-proxies/service-proxies';
import { selectAuthState } from '../core.state';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { NotificationService } from '../notifications/notification.service';

import {
  authLogin,
  authLogout,
  authFailure,
  authSuccess,
  authResetStates,
  authRefreshToken,
  authRefreshTokenSuccess,
  authRefreshTokenFailure,
  authSetInstitutions,
  authSetDefaultInstitution,
  authRefreshUser
} from './auth.actions';
import { AppStateAuth } from './auth.models';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  login = createEffect(() =>
    this.actions$.pipe(
      ofType(authLogin),
      mergeMap((action) =>
        this.authService.login(action.credentials).pipe(
          map(({ result }) =>
            authSuccess({
              response: {
                ...result,
                dateExpireToken: this.calculateExpirationToken(
                  result.expireInSeconds
                )
              }
            })
          ),
          catchError((error: any) => of(authFailure({ payload: error })))
        )
      )
    )
  );

  refreshToken = createEffect(() =>
    this.actions$.pipe(
      ofType(authRefreshToken),
      mergeMap((action) =>
        this.tokenService.refresh(action.credentials).pipe(
          map((response) =>
            authRefreshTokenSuccess({
              response: response.result,
              dateToExpire: this.calculateExpirationToken()
            })
          ),
          catchError((error: any) => of(authRefreshTokenFailure()))
        )
      )
    )
  );

  refreshTokenFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authRefreshTokenFailure),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([action, settings]) => {
          this._notificationService.warn(
            this.translate.instant(
              'genus.notifications.error.token.token-expired'
            )
          );
          this.store.dispatch(authLogout());
        })
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.store.dispatch(authResetStates());
          this.store.dispatch(actionResetProfile());
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false
          });
          this.router.navigate(['']);
        })
      ),
    { dispatch: false }
  );

  persistSettings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          authSuccess,
          authRefreshTokenSuccess,
          authSetInstitutions,
          authSetDefaultInstitution,
          authRefreshUser
        ),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([action, settings]) => {
          this.localStorageService.setItem(AUTH_KEY, settings);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppStateAuth>,
    private tokenService: TokenService,
    private _notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  calculateExpirationToken(secondsToExpire?: number): Date {
    const nowDate = new Date();
    nowDate.setSeconds(
      nowDate.getSeconds() + secondsToExpire
        ? secondsToExpire
        : environment.secondsToExpire
    );
    return nowDate;
  }
}
