import { AuthState } from './auth.models';
import {
  authLogin,
  authLogout,
  authFailure,
  authSuccess,
  authResetStates,
  authRefreshToken,
  authRefreshTokenSuccess,
  authRefreshTokenFailure,
  authSetDefaultInstitution,
  authSetInstitutions,
  authRefreshUser
} from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isLoading: false,
  errorMessage: null,
  isAuthFailure: false,
  isAuthSuccesfull: false,
  url: null
};

const reducer = createReducer(
  initialState,
  on(authLogin, (state, { credentials }) => ({
    ...state,
    isAuthenticated: false,
    isLoading: true
  })),
  on(authSuccess, (state, { response }) => ({
    ...state,
    isAuthenticated: true,
    user: { ...response },
    isLoading: false,
    isAuthSuccesfull: true
  })),
  on(authFailure, (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    isAuthFailure: true,
    errorMessage: payload
  })),
  on(authLogout, (state) => ({ ...state, isAuthenticated: false })),
  on(authResetStates, (state) => ({
    ...state,
    ...initialState
  })),
  on(authRefreshToken, (state, { url }) => ({
    ...state,
    isLoading: true,
    url: url
  })),
  on(authRefreshTokenSuccess, (state, { response, dateToExpire }) => ({
    ...state,
    user: {
      ...state.user,
      token: response.accessToken,
      refreshToken: response.refreshToken,
      dateExpireToken: dateToExpire
    },
    isLoading: false
  })),
  on(authRefreshTokenFailure, (state) => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    isAuthFailure: true
  })),
  on(authSetDefaultInstitution, (state, { institution }) => ({
    ...state,
    user: { ...state.user, defaultInstitution: institution }
  })),
  on(authSetInstitutions, (state, { institutions }) => ({
    ...state,
    user: { ...state.user, institutions: institutions }
  })),
  on(authRefreshUser, (state, { user }) => ({
    ...state,
    user: { ...state.user, ...user }
  }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
