import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  msjErrorGnrl =
    'Por favor verifica los datos ingresados. Si el error persiste comunicate con el Administrador';
  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse, point: string): string {
    let displayMessage;
    if (error.status === 401) {
      if (point === 'login') {
        const errorPayload = error['errorPayload'];
        if (errorPayload) {
          switch (errorPayload.title) {
            case 'Unauthorized':
              displayMessage =
                'La identificación ingresada no pertenece a ningún usuario registrado. Por favor, verifica la información y vuelve a intentarlo';
              break;
            case 'NOT_AUTHENTICATED':
              displayMessage =
                'La contraseña ingresada es incorrecta. Tiene ' +
                errorPayload.detail +
                ' intento(s) restantes';
              break;
            case 'LOCKEDOUT':
              displayMessage =
                'Usuario bloqueado. Intenta iniciar sesión en 5 minutos, o da clic en "Olvidé mi contraseña"';
              break;
            default:
              displayMessage =
                'Sucedió un error, por favor intentalo más tarde';
          }
        } else {
          displayMessage =
            'La identificación ingresada no se encuentra en Genus o la contraseña es incorrecta';
        }
      } else if (point === 'profile') {
        displayMessage = 'El token ha expirado, tiene que volver a ingresar';
      }
    } else if (error.status === 400) {
      if (point === 'user') {
        displayMessage =
          'La identificación o el correo ingresados ya existen en Genus. Por favor verifica la información y vuelve a intentarlo';
      } else {
        const errorPayload = error['errorPayload'];
        if (errorPayload) {
          switch (errorPayload.title.substr(0, 5)) {
            case 'Error':
              displayMessage =
                'La oferta seleccionada no tiene estudiantes matriculados';
              break;
            default:
              displayMessage = this.msjErrorGnrl;
          }
        } else {
          displayMessage = this.msjErrorGnrl;
        }
      }
    } else if (error.status === 500) {
      displayMessage =
        'Por favor verifica que los datos ingresados sean correctos y no se encuentren repetidos. Si el error persiste comunícate con el Administrador.';
    } else {
      displayMessage = 'Ocurrió un error.';
      if (!environment.production) {
        displayMessage += 'Consulte la consola para obtener más detalles.';
      }
    }
    return navigator.onLine ? displayMessage : 'Sin conexión a Internet';
  }

  getErrorFromObject(error): string[] {
    let errorMessages: string[] = [];
    const response = error.error
      ? error.error
      : error.errors
      ? error.errors
      : error.detail
      ? error.detail
      : error;
    if (error.status && error.status === 500) {
      return errorMessages;
    }
    if (response.response) {
      errorMessages.push(response.response);
    } else if (typeof response === 'string') {
      errorMessages.push(response);
    } else {
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          errorMessages.push(key + ': ' + response[key]);
        }
      }
    }
    return errorMessages;
  }
}
