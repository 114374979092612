import { createAction, props } from '@ngrx/store';
import {
  RequestProfileUpdate,
  ResponseInstitutionsByUser,
  ResponseUserProfile
} from '../../../shared/services/service-proxies/service-proxies';

export const actionUserUpdate = createAction(
  '[User] update',
  props<{ user: RequestProfileUpdate }>()
);

export const actionUserGet = createAction('[User] get');

export const actionSetUser = createAction(
  '[User] set',
  props<{ response: ResponseUserProfile }>()
);

export const actionFailGetUser = createAction(
  '[User] fail get',
  props<{ payload: any }>()
);

export const actionUpdateSuccesfull = createAction('[User] update success');

export const actionUpdateAvatarSucces = createAction(
  '[User] update avatar success',
  props<{ response: string }>()
);

export const actionResetProfile = createAction('[User] User reset');

export const actionUploadAvatar = createAction(
  '[User] update avatar',
  props<{ response: ResponseUserProfile }>()
);

export const actionUserFailUpdate = createAction(
  '[User] update user fail',
  props<{ payload: any }>()
);

export const actionGetUserInstitutions = createAction(
  '[User] Get user institutions',
  props<{ user: string }>()
);

export const actionsFailGetUserInstitutions = createAction(
  '[User] Fail get user institutions',
  props<{ payload: any }>()
);

export const actionSuccess = createAction('[User] Action success');

export const actionChangeUserInstitution = createAction(
  '[User] Change user institution',
  props<{ institution: ResponseInstitutionsByUser }>()
);

export const actionStoreUserInstitution = createAction(
  '[User] Store user institution',
  props<{ institution: ResponseInstitutionsByUser }>()
);

export const actionUpdateOnlyUserAvatar = createAction(
  '[User] Change user avatar',
  props<{ user: any }>()
);
