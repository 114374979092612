import { createAction, props } from '@ngrx/store';
import {
  RegisterEntity,
  ResponseResultAJAXOfIdentityResult
} from '../../shared/services/service-proxies/service-proxies';

export const actionUserRegister = createAction(
  '[User] register',
  props<{ user: RegisterEntity }>()
);

export const actionUserRegisterSuccess = createAction(
  '[User] register success',
  props<{ response: ResponseResultAJAXOfIdentityResult }>()
);

export const actionUserRegisterFail = createAction(
  '[User] register fail',
  props<{ payload: any }>()
);
