import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { selectAuth } from '../auth/auth.selectors';
import { UserFront } from '../auth/auth.models';
import { TokenService } from '../../shared/services/service-proxies/service-proxies';
import { authRefreshTokenSuccess } from '../auth/auth.actions';
import { environment } from '../../../environments/environment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  currentUser: UserFront;
  dateExpireToken: Date;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store, private tokenService: TokenService) {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      if (auth.isAuthenticated) {
        this.currentUser = auth.user;
        this.dateExpireToken = new Date(this.currentUser.dateExpireToken);
      } else {
        this.currentUser = null;
      }
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.search('lojasoftsolutions') > 0 &&
      this.currentUser &&
      this.currentUser.token
    ) {
      if (request.url.search('token/refresh') !== -1) {
        return next.handle(request);
      }
      if (this.isExpiredToken()) {
        if (!this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.tokenService
            .refresh({
              accessToken: this.currentUser.token,
              refreshToken: this.currentUser.refreshToken
            })
            .pipe(
              switchMap((response) => {
                let nowDate = new Date();
                nowDate.setSeconds(
                  nowDate.getSeconds() + environment.secondsToExpire
                );
                this.store.dispatch(
                  authRefreshTokenSuccess({
                    response: response.result,
                    dateToExpire: nowDate
                  })
                );
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(response.result);
                return next.handle(this.injectToken(request));
              }),
              catchError((error: HttpErrorResponse) => {
                this.refreshTokenInProgress = false;
                return throwError(error);
              })
            );
        } else {
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap((res) => {
              return next.handle(this.injectToken(request));
            })
          );
        }
      } else {
        return next.handle(this.injectToken(request));
      }
    }
    return next.handle(request);
  }

  injectToken(request: HttpRequest<any>) {
    return (request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.currentUser.token}`
      }
    }));
  }

  isExpiredToken(): boolean {
    const dateNow = new Date();
    return dateNow.valueOf() > this.dateExpireToken.valueOf();
  }
}
