import { createAction, props } from '@ngrx/store';
import {
  RequestLogin,
  ResponseInstitutionsByUser,
  ResponseRefreshToken,
  ResponseUserProfile,
  TokenApiEntity
} from '../../shared/services/service-proxies/service-proxies';
import { UserFront } from './auth.models';

// export const authLogin = createAction('[Auth] Login');
export const authLogout = createAction('[Auth] Logout');
export const authResetStates = createAction('[Auth] Reset States');
export const authLogin = createAction(
  '[Auth] Login',
  props<{ credentials: RequestLogin }>()
);
export const authSuccess = createAction(
  '[Auth] Login Success',
  props<{ response: UserFront }>()
);

export const authFailure = createAction(
  '[Auth] Login Failure',
  props<{ payload: any }>()
);

export const authRefreshToken = createAction(
  '[Auth] Refresh token',
  props<{ credentials: TokenApiEntity; url: string }>()
);

export const authRefreshTokenSuccess = createAction(
  '[Auth] Refresh token success',
  props<{ response: ResponseRefreshToken; dateToExpire: Date }>()
);

export const authRefreshTokenFailure = createAction(
  '[Auth] Refresh token failure'
);

export const authResetStatesWithUrl = createAction(
  '[Auth] Reset States with url',
  props<{ url: string }>()
);

export const authSetInstitutions = createAction(
  '[Auth] Set institutions',
  props<{ institutions: ResponseInstitutionsByUser[] }>()
);

export const authSetDefaultInstitution = createAction(
  '[Auth] Set default institution',
  props<{ institution: ResponseInstitutionsByUser }>()
);

export const authRefreshUser = createAction(
  '[Auth] Refresh user',
  props<{ user: ResponseUserProfile }>()
);
