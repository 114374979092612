import { createSelector } from '@ngrx/store';

import { selectRegisterState } from '../core.state';
import { RegisterState } from './register.model';

export const selectRegister = createSelector(
  selectRegisterState,
  (state: RegisterState) => state
);

export const selectIsRegister = createSelector(
  selectRegisterState,
  (state: RegisterState) => state.isRegisterSuccess
);
